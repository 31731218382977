import ColorService from '@services/ColorService';

/**
 * Filters and returns an array of items that are present in arr1 but not in arr2.
 * @returns array of items from arr1 that are not present in arr2
 */
export const missing = <T>(arr1: T[], arr2: T[]): T[] => (arr1 ? arr1.filter((item) => !arr2.includes(item)) : []);

type MapValuesToKeysIfAllowed<T> = {
	[K in keyof T]: T[K] extends PropertyKey ? K : never;
};
type Filter<T> = MapValuesToKeysIfAllowed<T>[keyof T];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GroupByValue = any;

export const groupBy = <T extends Record<PropertyKey, GroupByValue>, Key extends Filter<T>>(
	arr: T[],
	key: Key
): Record<T[Key], T[]> =>
	arr.reduce(
		(accumulator, val) => {
			const groupedKey = val[key];
			if (!accumulator[groupedKey]) {
				accumulator[groupedKey] = [];
			}
			accumulator[groupedKey].push(val);
			return accumulator;
		},
		{} as Record<T[Key], T[]>
	);

/**
 * Adding 's' to the word if length > 1
 * @param word singular word, f.e. chunk
 */
export const formatEnding = (word: string, length: number) => (length > 1 ? `${word}s` : word);

export const omit = <T, K extends keyof T>(item: T, key: K): Omit<T, K> =>
	Object.keys(item)
		.filter((k) => k !== key)
		.reduce<Omit<T, K>>((acc, k) => ({ ...acc, [k]: item[k] }), {} as Omit<T, K>);

/**
 * Sorts an array of strings based on their frequency of occurrence within the array,
 * and then finds the median value. The function returns items that occurring >= than median value
 * @param {string[]} items An array of strings. The same string can appear multiple times in the array.
 * @returns {string[]} array of strings that occurring in array the most
 */
export const getMostOccurred = (items: string[]) => {
	const dict = items.reduce<Record<string | number, number>>(
		(acc, item) => ({
			...acc,
			[item]: acc?.[item] !== undefined ? acc?.[item] + 1 : 1,
		}),
		{}
	);

	const itemsWithAmounts = Object.entries(dict)
		.map(([item, times]) => ({ item, times }))
		.sort((a, b) => a.times - b.times);

	const medianIndex = Math.floor(itemsWithAmounts.length / 2);
	const median = itemsWithAmounts[medianIndex] ? itemsWithAmounts[medianIndex].times : 0;

	return itemsWithAmounts.filter(({ times }) => times >= median).map(({ item }) => item);
};

export const removeDuplicates = <T>(tuples: [T, T][]) => {
	if (!Array.isArray(tuples) || tuples.some((t) => !Array.isArray(t))) {
		return tuples;
	}

	const filtered: [T, T][] = [];

	for (const tuple of tuples) {
		if (filtered.find((t) => t[0] === tuple[0] && t[1] == tuple[1])) {
			return;
		}

		filtered.push(tuple);
	}

	return filtered;
};

export const keysFrom = <T extends object>(object: T): (keyof T)[] => Object.keys(object) as (keyof T)[];

export const entriesFrom = <T extends object>(object: T): [keyof T, T[keyof T]][] =>
	Object.entries(object) as [keyof T, T[keyof T]][];

export const range = (min: number, max: number) => Array.from({ length: max - min + 1 }, (_, i) => i + min);

export const isInRange = (min: number, max: number) => (n: number) => n <= max && n >= min;

export const diff = <T>(obj1: T, obj2: Partial<T>): Partial<T> => {
	const differences: Partial<T> = {};

	for (const key in obj2) {
		if (obj2[key] !== obj1[key]) {
			differences[key] = obj2[key];
		}
	}

	return differences;
};

export const flattenObjectPaths = (obj: object, parent: string = ''): string[] => {
	// This function returns an array of all paths in the object
	// Example:
	// flattenObjectPaths({ a: 1, b: { c: 2, d: 3 } })
	// => ["a", "b.c", "b.d"]

	if (!obj) {
		return [];
	}
	return Object.keys(obj).reduce((acc, key) => {
		const path = parent ? `${parent}.${key}` : key;
		if (typeof obj[key] === 'object' && obj[key] !== null) {
			acc.push(...flattenObjectPaths(obj[key], path));
		} else {
			acc.push(path);
		}
		return acc;
	}, []);
};

/**
 * Splits an array into two arrays based on a predicate function.
 *
 * @template T The type of elements in the input array.
 * @param {T[]} arr - The input array to be split.
 * @param {function(T): boolean} predicate - A function that takes an item of type T and returns a boolean.
 * @returns {[T[], T[]]} A tuple containing two arrays:
 *   - The first array contains elements for which the predicate returns true.
 *   - The second array contains elements for which the predicate returns false.
 *
 * @example
 * const numbers = [1, 2, 3, 4, 5, 6];
 * const [evens, odds] = splitByPredicate(numbers, (n) => n % 2 === 0);
 * // evens: [2, 4, 6]
 * // odds: [1, 3, 5]
 */

export const splitByPredicate = <T>(arr: T[], predicate: (item: T) => boolean): [T[], T[]] =>
	arr.reduce(
		(acc, item) => {
			acc[predicate(item) ? 0 : 1].push(item);
			return acc;
		},
		[[], []] as [T[], T[]]
	);

export function wait(ms: number): Promise<void> {
	if (ms <= 0) {
		return Promise.resolve();
	}

	return new Promise((resolve) => setTimeout(resolve, ms));
}
